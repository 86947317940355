import React from 'react'
import { graphql } from 'gatsby'

// Components
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import SearchForm from '../components/SearchForm'

const Search = ({ data }) => {
  const index = data.localSearchPages.index
  const store = data.localSearchPages.store

  return (
    <>
      <Layout>
        <Seo title="Search" />
        <header className="masthead">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-10 mx-auto">
                <div className="site-heading">
                  <h1>Search</h1>
                  <span className="subheading">Looking for something?</span>
                </div>
              </div>
            </div>
          </div>
        </header>

        <div className="container" style={{ minHeight: '300px' }}>
          <div className="row">
            <div className="col-lg-8 col-md-10 mx-auto">
              <SearchForm index={index} store={store} />
            </div>
          </div>
        </div>
      </Layout>
    </>
  )
}

export const query = graphql`
  {
    localSearchPages {
      index
      store
    }
  }
`

export default Search
