import React, { useState } from 'react'
import { useLunr } from 'react-lunr'
import { Formik, Form, Field } from 'formik'

// React Icons
import { IoClose } from 'react-icons/io5'

// Components
import SearchResults from '../components/SearchResults'

const SearchForm = ({ index, store }) => {
  const [query, setQuery] = useState(null)
  const results = useLunr(query, index, store)
  const renderResult = results.length > 0 || query !== null ? true : false

  const onReset = () => setQuery(null)

  return (
    <>
      <Formik
        initialValues={{ query: '' }}
        onSubmit={(values, { setSubmitting }) => {
          setQuery(values.query)
          setSubmitting(false)
        }}
      >
        {formikProps => (
          <Form className="mb-5">
            <div className="form-group has-feedback has-clear">
              <Field
                className="form-control"
                name="query"
                placeholder="Search . . . . ."
                onChange={formikProps.handleChange}
                value={formikProps.values.query}
              />
            </div>
            <div className="row">
              <div className="col-12">
                <div className="text-right">
                  <button
                    type="submit"
                    className="btn btn-primary mr-1"
                    disabled={!formikProps.values.query}
                  >
                    Submit
                  </button>
                  <button
                    type="reset"
                    className="btn btn-primary"
                    value="Reset"
                    disabled={!formikProps.values.query}
                    onClick={(formikProps.resetForm, onReset)}
                  >
                    <IoClose />
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
      {renderResult && <SearchResults query={query} posts={results} />}
    </>
  )
}

export default SearchForm
