import React from 'react'
import { Link } from 'gatsby'

const SearchResults = ({ query, posts }) => {
  const pl = posts.length

  return (
    <>
      <div className="mb-5">
        <h2>
          {pl} Result{pl > 1 ? 's' : ''} for "<span style={{ color: '#895d24' }}>{query}</span>"
        </h2>
        {posts.map(post => {
          return (
            <React.Fragment key={post.id}>
              <div className="post-preview">
                <Link to={`/${post.slug}`}>
                  <h4 className="post-title">{post.title ? post.title : 'Missing Title'}</h4>
                </Link>
                <hr />
              </div>
            </React.Fragment>
          )
        })}
      </div>
    </>
  )
}

export default SearchResults
